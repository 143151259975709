/* global ga */

import { getJitsiMeetGlobalNS } from '../../base/util';

import AbstractHandler from './AbstractHandler';

/**
 * Analytics handler for Google Analytics.
 */
class GoogleAnalyticsHandler extends AbstractHandler {

    /**
     * Creates new instance of the GA analytics handler.
     *
     * @param {Object} options -
     * @param {string} options.googleAnalyticsTrackingId - The GA track id
     * required by the GA API.
     */
    constructor(options) {
        super(options);

        this._userProperties = {};

        if (!options.googleAnalyticsTrackingId) {
            throw new Error('Failed to initialize Google Analytics handler, no tracking ID');
        }

        this._enabled = true;
        this._initGoogleAnalytics(options);
    }

    /**
     * Initializes the ga object.
     *
     * @param {Object} options -
     * @param {string} options.googleAnalyticsTrackingId - The GA track id
     * required by the GA API.
     * @returns {void}
     */
    _initGoogleAnalytics(options) {
        /**
         * TODO: Keep this local, there's no need to add it to window.
         */
        /* eslint-disable */
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${options.googleAnalyticsTrackingId}`;
        head.appendChild(script);
        const scriptGtag = document.createElement('script');
        scriptGtag.text =
            `window.dataLayer = window.dataLayer || []; \n
            function gtag() { window.dataLayer.push(arguments); } \n
            gtag('js', new Date()); \n
            gtag('config', '${options.googleAnalyticsTrackingId}');`;
        head.appendChild(scriptGtag);
        /* eslint-enable */
    }

    /**
     * Extracts the integer to use for a Google Analytics event's value field
     * from a lib-jitsi-meet analytics event.
     *
     * @param {Object} event - The lib-jitsi-meet analytics event.
     * @returns {number} - The integer to use for the 'value' of a Google
     * analytics event, or NaN if the lib-jitsi-meet event doesn't contain a
     * suitable value.
     * @private
     */
    _extractValue(event) {
        let value = event && event.attributes && event.attributes.value;

        // Try to extract an integer from the "value" attribute.
        value = Math.round(parseFloat(value));

        return value;
    }

    /**
     * Extracts the string to use for a Google Analytics event's label field
     * from a lib-jitsi-meet analytics event.
     *
     * @param {Object} event - The lib-jitsi-meet analytics event.
     * @returns {string} - The string to use for the 'label' of a Google
     * analytics event.
     * @private
     */
    _extractLabel(event) {
        const { attributes = {} } = event;
        const labelsArray
            = Object.keys(attributes).map(key => `${key}=${attributes[key]}`);

        return labelsArray.join('&');
    }

    /**
     * Sets the permanent properties for the current session.
     *
     * @param {Object} userProps - The permanent portperties.
     * @returns {void}
     */
    setUserProperties(userProps = {}) {
        if (!this._enabled) {
            return;
        }

        this._userProperties = userProps;
    }

    /**
     * This is the entry point of the API. The function sends an event to
     * google analytics. The format of the event is described in
     * analyticsAdapter in lib-jitsi-meet.
     *
     * @param {Object} event - The event in the format specified by
     * lib-jitsi-meet.
     * @returns {void}
     */
    sendEvent(event) {
        if (this._shouldIgnore(event)) {
            return;
        }

        const action = this._extractName(event);
        const gaEvent = {
            'event_category': 'acer-smeet',
            'event_action': action,
            'event_label': this._extractLabel(event)
        };
        const value = this._extractValue(event);

        if (!isNaN(value)) {
            gaEvent.value = value;
        }

        const filter = ['user_agent', 'callstats_name'];
        Object.keys(this._userProperties)
            .filter(key => filter.indexOf(key) === -1)
            .map(key => {
                gaEvent[key] = this._userProperties[key];
            });

        gtag('event', action, gaEvent);
    }

}

const globalNS = getJitsiMeetGlobalNS();

globalNS.analyticsHandlers = globalNS.analyticsHandlers || [];
globalNS.analyticsHandlers.push(GoogleAnalyticsHandler);
